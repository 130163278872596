<template>
  <ion-grid :class="{ 'is-completed': selected, 'todo': !selected, 'is-required': isRequired }">
    <ion-row>
      <ion-col offset="6" size="6">
        <ion-list class="transparent-background">
          <ion-item class="transparent-background">
            <ion-select :placeholder="placeholder" interface="popover" @ionChange="onChange" :value="selected" :disabled="isDisabled">
              <ion-select-option v-for="item, index in items" :value="item" :key="index">{{item}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup lang="ts">
import type { Translatable } from '@/types/AssemblyFormType.interface';
import { IonList, IonItem, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol } from '@ionic/vue';
import { computed } from "vue";
import { storeToRefs } from 'pinia';
import { useEmployeeStore } from '@/stores/EmployeeStore';

const props = defineProps<{ 
  isDisabled: boolean,
  label: Translatable | null,
  isRequired: boolean | null,
  options: string | null,
  selected: string | null,
}>();

const emit = defineEmits(['update:selected', 'onUpdate', 'performActions']);

const employeeStore = useEmployeeStore();
const { activeEmployee } = storeToRefs(employeeStore);

const onChange = (event) => {
  emit('update:selected', event.detail.value);
  emit('onUpdate', () => {
    emit('performActions');
  });
}

const items = computed(() => {
  const _options = props.options.split(';');

  for (let i = 0; i < _options.length; i++) {
    _options[i] = _options[i].trim();
  }

  return _options;
});

const placeholder = computed(() => {
  return props.label[activeEmployee.value.language];
});

if (1 === items.value.length) {
  emit('update:selected', items.value[0]);

  if (props.selected !== items.value[0]) {
    emit('onUpdate');
  }
}

</script>

<style scoped>
span.label, span.expected-value {
  display: flex;
  width: 40%;
}
span.expected-value {
  font-weight: bold;
}
</style>
