<template>
    <ion-tab-bar>
        <slot name="bottom">
            <template v-for="{ name, completedAt }, index in stages" :key="name">
                <AssemblyProgressStage 
                    @click="onTabClick(index + 1)"
                    :index="index + 1" 
                    :isCompleted="!!completedAt" 
                    :isNext="(0 === index && !completedAt) || (0 !== index && !!stages[index - 1].completedAt)"
                    :isActive="index === (parseInt(route.params.index) - 1)" 
                    :label="name" 
                />
            </template>
        </slot>
    </ion-tab-bar>
</template>

<script setup lang="ts">
import { IonTabBar } from '@ionic/vue';
import { Stage } from '@/types/AssemblyFormType.interface';
import { useRoute, useRouter } from 'vue-router';
import AssemblyProgressStage from '@/components/AssemblyProgressStage.vue';

defineProps<{ 
    stages: any | Stage[],
}>();

const route = useRoute();
const router = useRouter();

const onTabClick = (index) => {
    router.push({
        name: 'AssemblyForm',
        params: {
            id: route.params.id,
            index: index,
        }
    });
}
</script>
