<template>
    <ion-button fill="clear" @click="$emit('setExpansion', !isExpanded)" :color="expansionButtonColor" :disabled="isLoading">
        <ion-icon v-if="isExpanded" slot="start" :icon="caretDownOutline" />
        <ion-icon v-else slot="start" :icon="caretForwardOutline" />
        {{prefix}} actions 
        &nbsp;<ion-badge v-if="uncompletedActions.length - failedActions.length">{{uncompletedActions.length - failedActions.length}}</ion-badge>
        &nbsp;<ion-badge v-if="failedActions.length" color="danger">{{failedActions.length}}</ion-badge>
    </ion-button>

    <IonList v-if="isExpanded" :inset="true" lines="inset">
        <IonItem v-for="action, key in fulfillmentActions" :key="key">
            <IonLabel class="layout-row layout-align-start-center">
                <span :title="action.bomItem.productDescription">{{action.bomItem.productNumber}}</span>
                <span class="flex"></span>
                <IonText v-if="action.error" color="danger">{{action.error}}</IonText>
                <span class="flex"></span>
                <span>Qty. {{action.quantity}}</span>
                <span class="flex-5"></span>
                <ion-spinner v-if="action.isLoading"></ion-spinner>
                <ion-icon v-else-if="action.completedAt" :icon="checkmarkCircleOutline" size="medium" color="success" />
                <ion-icon v-else-if="action.lastAttemptAt" :icon="alertCircleOutline" size="medium" color="danger" />
                <ion-icon v-else :icon="timeOutline" size="medium" />
            </IonLabel>
        </IonItem>
        <IonItem v-for="action, key in otherActions" :key="key">
            <IonLabel class="layout-row layout-align-start-center">
                <span>
                    <template v-if="'setVehicleProperty' === action.type">Vehicle property</template>
                    <template v-else>Action "{{action.type}}" not supported yet.</template>
                </span>
                <span class="flex"></span>
                <IonText v-if="action.error" color="danger">{{action.error}}</IonText>
                <span class="flex"></span>
                <span>
                    <template v-if="'setVehicleProperty' === action.type">
                        Set "{{action.properties.vehicleProperty.name}}" to
                        <template v-if="'boolean' === action.properties.vehicleProperty.type">
                            <span v-if="action.value">true</span>
                            <span v-else>false</span>
                        </template>
                        <template v-else>
                            "{{action.value}}"
                        </template>
                    </template>
                </span>
                <span class="flex-5"></span>
                <ion-spinner v-if="action.isLoading"></ion-spinner>
                <ion-icon v-else-if="action.completedAt" :icon="checkmarkCircleOutline" size="medium" color="success" />
                <ion-icon v-else-if="action.lastAttemptAt" :icon="alertCircleOutline" size="medium" color="danger" />
                <ion-icon v-else :icon="timeOutline" size="medium" />
            </IonLabel>
        </IonItem>
    </IonList>

    <ion-button v-if="failedActions.length || (uncompletedActions.length && isExecutable)" expand="block" fill="outline" size="large" @click="performUncompletedActions" :disabled="isLoading">
        <ion-icon :icon="refreshOutline" />
        Retry
    </ion-button>

    <!--<ion-button v-else-if="uncompletedActions.length && isExecutable" expand="block" fill="outline" size="large" @click="performUncompletedActions" :disabled="isLoading">
        Fulfill manually
    </ion-button>-->

    <div class="ion-padding-bottom"></div>
</template>

<script setup lang="ts">
import { IonButton, IonIcon, IonList, IonItem, IonLabel, IonSpinner, IonBadge, IonText } from '@ionic/vue';
import { caretForwardOutline, caretDownOutline, checkmarkCircleOutline, alertCircleOutline, timeOutline, refreshOutline } from 'ionicons/icons';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAssemblyStore } from '@/stores/AssemblyStore';
import type { Action } from '@/types/AssemblyFormType.interface';

const props = defineProps<{
    prefix: string,
    actions: Action[],
    isExpanded: boolean,
    isExecutable: boolean,
}>();

const emits = defineEmits([
  'setExpansion',
  'onActionsCompleted',
]);

const assemblyStore = useAssemblyStore();
const { isLoading } = storeToRefs(assemblyStore);

const fulfillmentActions = computed(() => {
    console.log(props.actions.filter((action: Action) => 'fulfillment' === action.type));
    return props.actions.filter((action: Action) => 'fulfillment' === action.type);
});
const otherActions = computed(() => {
    return props.actions.filter((action: Action) => 'fulfillment' !== action.type);
});
const failedActions = computed(() => {
    return props.actions.filter((action: Action) => !action.completedAt && action.lastAttemptAt);
});
const uncompletedActions = computed(() => {
    return props.actions.filter((action: Action) => !action.completedAt);
});
const expansionButtonColor = computed(() => {
    if (failedActions.value.length) {
        return 'danger';
    }
    if (uncompletedActions.value.length) {
        return 'primary';
    }
    return 'success';
});

const performUncompletedActions = async () => {
    for (const action of uncompletedActions.value) {
        await assemblyStore.performAction(action);
    }

    emits('onActionsCompleted');
}

</script>