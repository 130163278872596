<template>
  <ion-grid :class="{ 'is-completed': _customer.name, 'todo': !_customer.name, 'is-required': isRequired }">
    <ion-row>
      <ion-col offset="6" size="6">
        <ion-item class="transparent-background">
          <ion-label v-if="error">{{error}}</ion-label>
          <ion-label v-if="label">
            <TranslatableProperty :translatable="label" />
          </ion-label>
        </ion-item>
        <ion-item class="transparent-background">
          <ion-label>Name</ion-label>
          <ion-input v-model="_customer.name" type="text" placeholder="Name" @ionChange="onChange" :disabled="isDisabled"></ion-input>
        </ion-item>
        <ion-item class="transparent-background">
          <ion-label>Address</ion-label>
          <ion-input v-model="_customer.address" type="text" placeholder="Address" @ionChange="onChange" :disabled="isDisabled"></ion-input>
        </ion-item>
        <ion-item class="transparent-background">
          <ion-label>Zipcode</ion-label>
          <ion-input v-model="_customer.zipcode" type="text" placeholder="Zipcode" @ionChange="onChange" :disabled="isDisabled"></ion-input>
        </ion-item>
        <ion-item class="transparent-background">
          <ion-label>Location</ion-label>
          <ion-input v-model="_customer.location" type="text" placeholder="Location" @ionChange="onChange" :disabled="isDisabled"></ion-input>
        </ion-item>
        <ion-item class="transparent-background">
          <ion-label>Country</ion-label>
          <ion-input v-model="_customer.country" type="text" placeholder="Country" @ionChange="onChange" :disabled="isDisabled"></ion-input>
        </ion-item>
        <ion-item class="transparent-background">
          <ion-label>Extra info</ion-label>
          <ion-input v-model="_customer.info" type="text" placeholder="Extra info" @ionChange="onChange" :disabled="isDisabled"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup lang="ts">
import type { Customer } from '@/types/CustomerType.interface';
import type { Translatable } from '@/types/AssemblyFormType.interface';
import { IonLabel, IonItem, IonGrid, IonRow, IonCol, IonInput } from '@ionic/vue';
import { ref, toRef } from "vue";
import { storeToRefs } from 'pinia';
import { useAssemblyStore } from '@/stores/AssemblyStore';
import { fetchWrapper } from "@/helpers/fetch-wrapper";
import TranslatableProperty from '@/components/TranslatableProperty.vue';

const props = defineProps<{ 
  isDisabled: boolean,
  label: Translatable | null,
  isRequired: boolean | null,
  useVehicleCustomerByDefault: boolean | null,
  customer: Customer | null,
}>();

const assemblyStore = useAssemblyStore();
const { productUnitId, salesOrderId } = storeToRefs(assemblyStore);

/** @type {Customer} */
const _customer = ref({
  name: '',
  address: '',
  zipcode: '',
  location: '',
  country: '',
  info: '',
});

/** @type {Customer} */
const customerRef = toRef(props, 'customer');

const emit = defineEmits(['update:customer', 'onUpdate', 'performActions']);

const isLoading = ref(false);
const error = ref('');

const onChange = () => {
  emit('update:customer', _customer.value);
  emit('onUpdate', () => {
    emit('performActions');
  });
}

if (customerRef.value) {
  _customer.value = customerRef.value;
}

(async () => {
  if (props.useVehicleCustomerByDefault && (!customerRef.value || !customerRef.value.name)) {
    let response;

    // Check if the productUnit has a salesOrder
    if (salesOrderId.value) {
      isLoading.value = true;

      try {
          response = await fetchWrapper.get(`/api/customers?salesOrder=${salesOrderId.value}&include=addresses`);
      } catch (_error: any) {
          error.value = _error;
      } finally {
          isLoading.value = false;
      }

      if (1 === response.data.length) {
          const customer = response.data[0].attributes;

          _customer.value.name = customer.organization ?? customer.name;
          emit('update:customer', _customer.value);

          // Address
          if ('addresses' in response.data[0].relationships 
              && 1 === response.data[0].relationships.addresses.data.length
              && 'included' in response
          ) {
            const address = response.included.filter((resource) => {
              return 'Address' === resource.type;
            })[0].attributes;

            _customer.value.address = address.street;
            _customer.value.zipcode = address.zipcode;
            _customer.value.location = address.location;
            _customer.value.country = address.countryCode;

            emit('update:customer', _customer.value);
          }
      }
    } else {
      // Check if the customer is set on the vehicle
      isLoading.value = true;

      try {
          response = await fetchWrapper.get(`/api/vehicles?productUnit=${productUnitId.value}`);
      } catch (_error: any) {
          error.value = _error;
      } finally {
          isLoading.value = false;
      }
      
      if (1 === response.data.length) {
        if ('customer' in response.data[0].relationships) {
          const customerId = response.data[0].relationships.customer.data.id.split('/').pop();

          isLoading.value = true;

          try {
              response = await fetchWrapper.get(`/api/customers/${customerId}`);
          } catch (_error: any) {
              error.value = _error;
          } finally {
              isLoading.value = false;
          }

          const customer = response.data.attributes;

          _customer.value.name = customer.organization ?? customer.name;
          emit('update:customer', _customer.value);
          emit('onUpdate');
        }                
      }
    }
  }
})();
</script>
