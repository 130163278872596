<template>
  <ion-page :class="{ 'is-scanning': isScanning }">
    <ScanOverlay v-if="isScanning" @cancel="setIsScanning(false)" />

    <ion-content ref="scrollableContent" v-if="stage" :fullscreen="true" class="ion-padding-top" :class="{ 'transparent-background': isScanning }">
      <ion-grid v-if="!isScanning">
        <ion-row>
          <ion-col size="5" class="layout-row layout-align-start-center">
            <ActiveEmployee :currentLevel="stage.minWorkerLevel" />
          </ion-col>
          <ion-col size="7" offset="0" class="layout-row layout-align-end-center">
            <ActiveAssembly />    
          </ion-col>
        </ion-row>
      </ion-grid>

      <hr v-if="!isScanning" />

      <template v-for="section, index in stage.sections" :key="index">
        <AssemblyFormSection 
          :actions="section.actions ?? []"
          :name="section.name" 
          :items="section.items" 
          :isScanning="isScanning" 
          :isDisabled="!canEdit"
          @setIsScanning="setIsScanning" 
          @performSectionActions="performSectionActions(section)"
          @revertSectionActions="revertSectionActions(section)"
          @onActionsCompleted="onSectionActionsCompleted(section)"
        />
      </template>

      <section v-if="!stage.completedAt" class="full-width ion-margin-top ion-padding-top ion-padding-bottom ion-margin-bottom">
        <ion-button @click="onCompleteStageClick" :disabled="!canComplete || isLoading" expand="full" :color="finishButtonColor">
          <ion-progress-bar v-if="isLoading && stageActionsProgress" :value="stageActionsProgress"></ion-progress-bar>
          Finish &nbsp; <strong>{{stage.name}}</strong>
        </ion-button>

        <FormActions 
            v-if="stage.actions.length"
            prefix="Stage"
            :actions="stage.actions"
            :isExpanded="actionsIsExpanded"
            :isExecutable="false"
            @setExpansion="(isExpanded) => actionsIsExpanded = isExpanded"
        />
      </section>
    </ion-content>

    <AssemblyProgress :stages="stages" v-if="!isScanning" />
  </ion-page>
</template>

<script setup lang="ts">
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton, loadingController, IonProgressBar } from '@ionic/vue';
import { ref, computed, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { useAssemblyStore } from '@/stores/AssemblyStore';
import { useEmployeeStore } from '@/stores/EmployeeStore';
import { useRoute, useRouter } from 'vue-router';
import AssemblyProgress from '@/components/AssemblyProgress.vue';
import AssemblyFormSection from '@/components/AssemblyFormSection.vue';
import FormActions from '@/components/FormActions.vue';
import ActiveEmployee from '@/components/ActiveEmployee.vue';
import ActiveAssembly from '@/components/ActiveAssembly.vue';
import ScanOverlay from '@/components/ScanOverlay.vue';
import type { Stage, Action } from '@/types/AssemblyFormType.interface';

const assemblyStore = useAssemblyStore();
const employeeStore = useEmployeeStore();
const route = useRoute();
const router = useRouter();
const { assemblyForm, isLoading } = storeToRefs(assemblyStore);
const { activeEmployee } = storeToRefs(employeeStore);
const isScanning = ref(false);
const actionsIsExpanded = ref(false);
const hasStageActionErrors = ref(false);
const stageActionsProgress = ref(0);
const scrollableContent = ref(null);
let scrollPositionBeforeScan = 0;

const stage = computed<Stage>(() => {
  if (!assemblyForm || !assemblyForm.value || !assemblyForm.value.stages) {
    return null;
  }

  return assemblyForm.value.stages[parseInt(route.params.index) - 1];
});

const stages = computed<Stage[]>(() => {
  if (!assemblyForm || !assemblyForm.value || !assemblyForm.value.stages) {
    return [];
  }

  return assemblyForm.value.stages;
});

const canComplete = computed(() => {
  for (const section of stage.value.sections) {
    for (const item of section.items) {
        for (const component of item.components) {
            if (component.properties.isRequired && !component.completedAt) {
                return false;
            }
        }
    }
  }

  return true;
});

const canEdit = computed<boolean>(() => {
  if (activeEmployee.value.level < stage.value.minWorkerLevel) {
    return false;
  }

  const stageIndex = parseInt(route.params.index) - 1;

  if (stage.value.completedAt) {
    return false;
  }

  if (0 === stageIndex) {
    return true;
  }

  if (stages.value[stageIndex - 1].completedAt) {
    return true;
  }

  return false;
});

const finishButtonColor = computed(() => {
  if (isLoading.value) {
    return 'medium';
  }

  if (hasStageActionErrors.value) {
    return 'warning';
  }

  return '';
});

if (assemblyForm) {
  assemblyStore.find(parseInt(route.params.id));
}

employeeStore.load();

const setIsScanning = (async (value: boolean) => {
  if (value) {
    scrollPositionBeforeScan = (await scrollableContent.value.$el.getScrollElement()).scrollTop;
  }

  isScanning.value = value;

  if (!value && scrollPositionBeforeScan) {
    await nextTick();

    setTimeout(() => {
      scrollableContent.value.$el.scrollToPoint(0, scrollPositionBeforeScan);
      scrollPositionBeforeScan = 0;
    }, 50);
  }
})

const performSectionActions = async (section) => {
    if ('actions' in section) {
        const actions = section.actions.filter((action: Action) => !action.completedAt);

        for (const action of actions) {
            await assemblyStore.performAction(action);
        }
    }
    
    onSectionActionsCompleted(section);
}

const revertSectionActions = async (section) => {
    if (!('actions' in section)) {
        return;
    }

    const actions = section.actions.filter((action: Action) => action.completedAt);

    for (const action of actions.reverse()) {
        await assemblyStore.revertAction(action);
    }  

    if (0 < section.actions.filter((action: Action) => !action.completedAt).length) {
        section.completedAt = null;
    }
}

const onSectionActionsCompleted = (section) => {
    if ('actions' in section) {
        for (const action of section.actions) {
            if (!action.completedAt) {
                // SoundEffect.play({ id: 'error' });
                return;
            }
        }
    }
    
    section.completedAt = (new Date()).toISOString();

    // There is propagation for if the section is finished.

    // SoundEffect.play({ id: 'click' });
}

const onCompleteStageClick = async () => {
  hasStageActionErrors.value = false;
  stageActionsProgress.value = 0;

  const loading = await loadingController.create({
    message: 'Saving...',
    spinner: 'circles'
  });
    
  loading.present();

  const actions = stage.value.actions.filter(action => !action.completedAt);

  for (const [index, action] of actions.entries()) {
    stageActionsProgress.value = index / actions.length;
    await assemblyStore.performAction(action);
  }

  stageActionsProgress.value = 0;

  if (0 < stage.value.actions.filter(action => !action.completedAt).length) {
    loading.dismiss();
    hasStageActionErrors.value = true;
    actionsIsExpanded.value = true;
    return;
  }

  await assemblyStore.completeStage(stage.value);
  
  stage.value.completedAt = (new Date()).toISOString();

  loading.dismiss();
  
  assemblyStore.reset();
  
  router.push({
    name: "UnitSelector"
  });
}
</script>

<style>
/*
:not(.is-scanning) .is-completed {
  background-color: #a3ffa7;
}
*/
:not(.is-scanning) .component .todo.is-required {
  border-radius: 10px;
  background-color: #a3dfff73;
}
:not(.is-scanning) .component .is-completed {
  border-radius: 10px;
  background-color: #a3ffa673;
}
/*:not(.is-scanning) .todo.is-required::before {
  content: "👉🏻";
  font-size: 32px;
  padding: 0 12px;
  left: 0;
  position: absolute;
}*/
.transparent-background {
    --background: none;
    background: none;
}
.layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex !important;
}
.layout-column {
  flex-direction: column;
}
.layout-row {
  flex-direction: row;
}
.layout-align-start-center {
  align-items: center;
  align-content: center;
  max-width: 100%;
}
.layout-align-end, .layout-align-end-center, .layout-align-end-end, .layout-align-end-start, .layout-align-end-stretch {
  justify-content: flex-end;
}
.layout-align-center-center, .layout-align-end-center, .layout-align-space-around-center, .layout-align-space-between-center, .layout-align-start-center {
  align-items: center;
  align-content: center;
  max-width: 100%;
}
.layout-align-start, .layout-align-start-center, .layout-align-start-end, .layout-align-start-start, .layout-align-start-stretch {
  justify-content: flex-start;
}
.layout-align-start-center > *, .layout-align-center-center > *, .layout-align-end-center > *, .layout-align-space-between-center > *, .layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box;
}
.flex {
  flex: 1;
  min-width: 0;
  box-sizing: border-box;
}
.flex-5, .layout-row > .flex-5 {
  flex: 1 1 100%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

hr {
	background-color: #ededed;
}
.is-scanning hr {
  opacity: 0;
  display: none;
}

ion-icon.icon-medium {
  font-size: 24px;
}

ion-button ion-progress-bar {
  position: absolute;
  padding: 18px;
  width: calc(100% + 30px);
  z-index: -1;
}
ion-checkbox {
  --background-checked: #03ba00c9;
  --border-color-checked: #03bd00c9;
}
</style>
