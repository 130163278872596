<template>
  <ion-page class="ion-padding-top">
    <ion-content :fullscreen="true">
      <ion-toolbar class="ion-margin-top">
          <ion-title size="large">Select active employee</ion-title>
      </ion-toolbar>
      
      <ion-list>
        <ion-item v-for="employee, index in employees" :value="employee.name" :key="index">
          <ion-label button @click="onEmployeeClick(employee)">{{employee.name}}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonContent, IonPage, IonList, IonItem, IonLabel, IonToolbar, IonTitle, loadingController } from '@ionic/vue';
import { storeToRefs } from 'pinia';
import { useEmployeeStore } from '@/stores/EmployeeStore';
import { useRouter } from 'vue-router';

const employeeStore = useEmployeeStore();
const router = useRouter();
const { employees } = storeToRefs(employeeStore);

const onEmployeeClick = (employee) => {
  employeeStore.setActive(employee);

  router.push({
    name: 'UnitSelector',
  });
}

(async () => {
  const loading = await loadingController.create({
    message: 'Loading employees...',
    spinner: 'circles'
  });

  loading.present();

  await employeeStore.load();

  loading.dismiss();
})();

</script>

<style scoped>
ion-label, ion-item {
  font-size: 24px;
}
</style>