<template>
  <ion-grid :class="{ 'is-completed': text, 'todo': !text, 'is-required': isRequired }">
    <ion-row>
      <ion-col offset="6" size="6">
        <ion-item class="transparent-background">
          <ion-label position="floating" v-if="label"><TranslatableProperty :translatable="label" /></ion-label>
          <ion-textarea v-model="initialValue" @ionChange="onChange" @ionBlur="onBlur" :disabled="isDisabled"></ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup lang="ts">
import type { Translatable } from '@/types/AssemblyFormType.interface';
import { IonGrid, IonCol, IonRow, IonItem, IonLabel, IonTextarea } from '@ionic/vue';
import { ref, toRef } from "vue";
import TranslatableProperty from '@/components/TranslatableProperty.vue';

const props = defineProps<{ 
  isDisabled: boolean,
  label?: Translatable | null,
  isRequired: boolean | null,
  text: string | null,
}>();

const _text = toRef(props, 'text');
const initialValue = ref('');

const emit = defineEmits(['update:text', 'onUpdate', 'performActions']);

const onChange = (event) => {
  emit('update:text', event.detail.value);
}

const onBlur = () => {
  emit('onUpdate', () => {
    emit('performActions');
  });
}

initialValue.value = _text.value;
</script>