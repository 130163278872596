<template>
    <ion-content :fullscreen="true" class="transparent-background ion-text-center">
        <div class="camera-scope"></div>
        <div class="scan-options-container">
            <!-- <ion-button @click="toggleTorch" expand="block" fill="outline" size="large" class="btn-scanning-torch">Torch</ion-button> -->
            <!-- <br /> -->
            <ion-button @click="onCancel" expand="block" fill="outline" size="large" class="btn-scanning-cancel">Cancel</ion-button>
        </div>
    </ion-content>
</template>

<script setup lang="ts">
import { IonContent, IonButton } from '@ionic/vue';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Capacitor } from '@capacitor/core';

const emit = defineEmits(['cancel']);

const toggleTorch = () => {
  if (Capacitor.isNativePlatform()) {
    BarcodeScanner.toggleTorch();
  }
}

const onCancel = async () => {
  if (Capacitor.isNativePlatform()) {
    document.querySelector('body')?.classList.remove('barcode-scanner-active');

    await BarcodeScanner.stopScan();
  }  

  emit('cancel');
}
</script>

<style scoped>
ion-content {
  z-index: 10;
}
.transparent-background {
    --background: none;
}
.camera-scope {
  position: absolute;
  display: inline-block;
  /* left: 0; */
  /* right: 0; */
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 50vw;
  outline: 200vw solid #ffffffd4;
}
.scan-options-container {
  position: absolute;
  display: inline-block;
  transform: translateX(-50%);
  bottom: 10vh;
  width: 40vw;
}

.btn-scanning-cancel {
  position: absolute;
  display: inline-block;
  transform: translateX(-50%);
  bottom: 10vh;
  width: 40vw;
}

ion-input {
  border: 1px solid;
  border-radius: 10px;
  height: 70px;
  font-size: 28px;
}
</style>
