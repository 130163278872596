<template>
    <div class="transparent-background layout-row layout-align-start-center ion-margin-top">
        <!-- Workaround for not being able to update the selected option -->
        <ion-select v-if="currentLevel > activeEmployee.level" placeholder="Employee" interface="popover" @ionChange="onChange" v-model="activeEmployeeName">
            <ion-select-option v-for="employee, index in employees" :value="employee.name" :key="index">
                {{employee.name}}
                <span v-if="currentLevel > employee.level">⚠️</span>
            </ion-select-option>
        </ion-select>
        <ion-select v-else placeholder="Employee" interface="popover" @ionChange="onChange" v-model="activeEmployeeName">
            <ion-select-option v-for="employee, index in employees" :value="employee.name" :key="index">
                {{employee.name}}
                <span v-if="currentLevel > employee.level">⚠️</span>
            </ion-select-option>
        </ion-select>
    </div>
</template>

<script setup lang="ts">
import { IonSelect, IonSelectOption } from '@ionic/vue';
import { storeToRefs } from 'pinia';
import { useEmployeeStore } from '@/stores/EmployeeStore';

defineProps<{
    currentLevel?: number,
}>();

const employeeStore = useEmployeeStore();
const { employees, activeEmployee } = storeToRefs(employeeStore);
let activeEmployeeName;

const onChange = (event) => {
    employeeStore.setActiveByName(event.detail.value);
    activeEmployeeName = event.detail.value;
}

if (activeEmployee.value && activeEmployee.value.name) {
    activeEmployeeName = activeEmployee.value.name;
}

employeeStore.load();
</script>

<style scoped>
ion-select {
    font-size: 20px;
}
</style>
