<template>
  <ion-grid :class="{ 'is-completed': isConfirmed, 'todo': !isConfirmed, 'is-required': isRequired }">
    <ion-row>
      <ion-col class="ion-text-start layout-row layout-align-start-center" :size="expectedValue ? 5 : 10">
        <span v-if="label" class="label">
          <TranslatableProperty :translatable="label" />
        </span>
      </ion-col>
      <ion-col class="ion-text-start layout-row layout-align-start-center" size="5" v-if="expectedValue">
        <span v-if="expectedValue" class="expected-value">
          <TranslatableProperty :translatable="expectedValue" />
        </span>
      </ion-col>
      <ion-col size="2" class="ion-text-end layout-row layout-align-end-center">
        <ion-checkbox @update:modelValue="onToggle" :modelValue="isConfirmed" :disabled="isDisabled"></ion-checkbox>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup lang="ts">
import type { Translatable } from '@/types/AssemblyFormType.interface';
import { IonGrid, IonRow, IonCol, IonCheckbox } from '@ionic/vue';
import TranslatableProperty from '@/components/TranslatableProperty.vue';

const props = defineProps<{
    isDisabled: boolean,
    label?: Translatable | null,
    isRequired: boolean | null,
    expectedValue?: Translatable | null,
    isConfirmed: boolean | null,
}>();

const emit = defineEmits([
  'update:isConfirmed', 
  'onUpdate',
  'performActions',
  'revertActions',
]);

const onToggle = (value) => {
  if (value === props.isConfirmed) {
    return;
  }
  
  emit('update:isConfirmed', value);
  emit('onUpdate', () => {
    if (value) {
      emit('performActions');
    } else {
      emit('revertActions');
    }
  });
}
</script>

<style scoped>
span.expected-value {
  font-weight: bold;
}
ion-checkbox {
  height: 40px;
  width: 40px;
  margin: -4px;
}
</style>
