<template>
  <ion-page>    
    <ion-content :fullscreen="true">    
      <div id="container">      
        <ion-grid>
            <ion-row>
                <ion-col offset-md="3" size-md="6">
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title>Login</ion-card-title>
                        </ion-card-header>

                        <ion-card-content>
                            <p v-if="error">{{error}}</p>

                            <ion-input v-model="username"></ion-input>
                            <ion-input v-model="password" type="password"></ion-input>

                            <ion-button @click="authenticate" expand="block" fill="solid" size="large" :disabled="isLoading">Confirm</ion-button>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonContent, IonPage, IonButton, IonCol, IonGrid, IonRow, IonInput, IonCard, IonCardTitle, IonCardHeader, IonCardContent } from '@ionic/vue';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/AuthStore';
import { useEmployeeStore } from '@/stores/EmployeeStore';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();
const employeeStore = useEmployeeStore();
const { isLoading, error } = storeToRefs(authStore);

const username = ref('robinjansen51@gmail.com');
const password = ref();

const authenticate = async () => {
    employeeStore.clear();
    await authStore.authenticate(username.value, password.value);
    
    router.push('/select');
}
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
