<template>
    {{ translated }}
</template>

<script setup lang="ts">
import type { Translatable } from '@/types/AssemblyFormType.interface';
import { useEmployeeStore } from '@/stores/EmployeeStore';
import { storeToRefs } from 'pinia';
import { computed } from "vue";

const props = defineProps<{ 
    translatable?: Translatable,
}>();

const employeeStore = useEmployeeStore();
const { activeEmployee } = storeToRefs(employeeStore);

const translated = computed(() => {
    return props.translatable[activeEmployee.value.language ?? 'nl'];
});
</script>