<template>
  <ion-grid :class="{ 'is-completed': date, 'todo': !date, 'is-required': isRequired }">
    <ion-row>
      <ion-col offset="6" size="6">
        <ion-item class="transparent-background">
          <ion-label v-if="label">
            <TranslatableProperty :translatable="label" />
          </ion-label>
          <ion-datetime id="datetime" :value="initialValue" presentation="date" @ionChange="onChange" :disabled="isDisabled"></ion-datetime>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup lang="ts">
import type { Translatable } from '@/types/AssemblyFormType.interface';
import { IonGrid, IonCol, IonRow, IonItem, IonLabel, IonDatetime } from '@ionic/vue';
import { ref, toRef } from "vue";
import TranslatableProperty from '@/components/TranslatableProperty.vue';

const props = defineProps<{ 
  isDisabled: boolean,
  label?: Translatable | null,
  isRequired: boolean | null,
  date: string | null,
}>();

const _date = toRef(props, 'date');
const initialValue = ref('');

const emit = defineEmits(['update:date', 'onUpdate', 'performActions']);

const onChange = (event) => {
  initialValue.value = event.detail.value;
  emit('update:date', event.detail.value.split('T')[0]);
  emit('onUpdate', () => {
    emit('performActions');
  });
}

if (_date.value) {
  initialValue.value = _date.value;
} else {
  const currentDate = (new Date()).toISOString();
  initialValue.value = currentDate;
  emit('update:date', currentDate.split('T')[0]);
  emit('onUpdate');
}
</script>