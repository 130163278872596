<template>
  <ion-grid :class="{ 'is-completed': employee, 'todo': !employee, 'is-required': isRequired }">
    <ion-row>
      <ion-col offset="6" size="6">
        <ion-list class="transparent-background">
          <ion-item class="transparent-background">
            <ion-select :placeholder="placeholder" interface="popover" @ionChange="onChange" :value="employee" :disabled="isDisabled">
              <ion-select-option v-for="_employee, index in employees" :value="_employee.name" :key="index">
                {{_employee.name}}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup lang="ts">
import type { Translatable } from '@/types/AssemblyFormType.interface';
import { IonItem, IonGrid, IonRow, IonCol, IonList, IonSelect, IonSelectOption } from '@ionic/vue';
import { storeToRefs } from 'pinia';
import { useEmployeeStore } from '@/stores/EmployeeStore';
import { computed } from 'vue';

const props = defineProps<{ 
  isDisabled: boolean,
  label?: Translatable | null,
  isRequired: boolean | null,
  employee?: string | null,
}>();

const emit = defineEmits(['update:employee', 'onUpdate', 'performActions']);
const employeeStore = useEmployeeStore();
const { employees, activeEmployee } = storeToRefs(employeeStore);

const onChange = (event) => {
  emit('update:employee', event.detail.value);
  emit('onUpdate', () => {
    emit('performActions');
  });
}

const placeholder = computed(() => {
  if (!props.label) {
    return '';
  }
  
  return props.label[activeEmployee.value.language];
});

employeeStore.load();

if (!props.employee && activeEmployee.value.name) {
  emit('update:employee', activeEmployee.value.name);
  emit('onUpdate');
}
</script>
