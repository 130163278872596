<template>
  <template v-if="!isScanning">
    <div v-if="serialNumber" class="serial-number-container ion-text-end ion-padding-end is-completed" :class="{ 'is-required': isRequired }">
      <ion-button color="medium" @click="onClearClick" :disabled="isDisabled">
        <ion-icon slot="start" :icon="closeOutline"></ion-icon>
        clear
      </ion-button>
      
      <span class="serial-number">{{serialNumber}}</span>
    </div>

    <div v-if="!serialNumber" class="ion-text-end ion-padding-end todo" :class="{ 'is-required': isRequired }">
      <ion-button color="medium" @click="setManualEntering(true)" :disabled="isDisabled">
        <ion-icon slot="start" :icon="pencil"></ion-icon>
        Handmatig
      </ion-button>

      <ion-button color="medium" class="ion-padding-start" @click="startScan" :disabled="isDisabled" v-if="Capacitor.isNativePlatform()">
        <ion-icon slot="start" :icon="barcodeOutline"></ion-icon>
        Scan {{ props.scanTargetFormat ? props.scanTargetFormat : 'code' }}
      </ion-button>
      
      <ion-modal ref="modal" :isOpen="isManualEntering" @willDismiss="onWillDismiss">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click="setManualEntering(false)">Cancel</ion-button>
            </ion-buttons>
            <ion-title>Serial number</ion-title>
            <ion-buttons slot="end">
              <ion-button :strong="true" @click="onConfirmManualInput">Confirm</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-item ref="item">
            <ion-label position="stacked">Enter serial number</ion-label>
            <ion-input ref="input" type="text" @ionInput="validate" placeholder="serial number"></ion-input>
            <ion-note slot="error">Incorrect serienummer</ion-note>
          </ion-item>
        </ion-content>
      </ion-modal>
    </div>
  </template>
</template>

<script setup lang="ts">
import { actionSheetController, IonButton, IonIcon, IonModal, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonItem, IonLabel, IonInput, IonNote } from '@ionic/vue';
import { OverlayEventDetail } from '@ionic/core/components';
import { barcodeOutline, pencil, closeOutline } from 'ionicons/icons';
import { BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { Capacitor } from '@capacitor/core';
import { Haptics } from '@capacitor/haptics';
import { ref } from 'vue';

const props = defineProps<{
  isDisabled: boolean,
  isRequired?: boolean | null,
  isScanning: boolean,
  productRevision?: number | null,
  serialNumber?: string | null,
  regex?: string | null,
  scanTargetFormat?: string | null,
}>();

const emit = defineEmits([
  'update:serialNumber',
  'setIsScanning',
  'onUpdate',
  'performActions',
  'revertActions',
]);

const modal = ref(null);
const input = ref(null);
const item = ref(null);
const isManualEntering = ref(false);

const startScan = async () => {  
  if (!Capacitor.isNativePlatform()) {
    return;
  }
  
  emit('setIsScanning', true);

  setTimeout(() => {
    BarcodeScanner.enableTorch();
  }, 100);

  const listener = await BarcodeScanner.addListener('barcodeScanned',
    async result => {
      if (!props.isScanning) {
        // Avoid double scans
        return;
      }

      await BarcodeScanner.removeAllListeners();
      await BarcodeScanner.stopScan();
      await Haptics.vibrate();

      emit('setIsScanning', false);
      document.querySelector('body')?.classList.remove('barcode-scanner-active');
      
      emit('update:serialNumber', result.barcode.rawValue);
      emit('onUpdate', () => {
        emit('performActions');
      });
    },
  );

  const formats = [];

  if ('barcode' === props.scanTargetFormat) {
    formats.push(BarcodeFormat.Codabar);
    formats.push(BarcodeFormat.Code39);
    formats.push(BarcodeFormat.Code93);
    formats.push(BarcodeFormat.Code128);
    formats.push(BarcodeFormat.Ean8);
    formats.push(BarcodeFormat.Ean13);
    formats.push(BarcodeFormat.Itf);
    formats.push(BarcodeFormat.QrCode);
    formats.push(BarcodeFormat.UpcA);
    formats.push(BarcodeFormat.UpcE);
  }

  if ('qrcode' === props.scanTargetFormat) {
    formats.push(BarcodeFormat.Aztec);
    formats.push(BarcodeFormat.DataMatrix);
    formats.push(BarcodeFormat.Pdf417);
    formats.push(BarcodeFormat.QrCode);
  }

  await BarcodeScanner.startScan({
    formats: formats,
  });
};

const onConfirmManualInput = () => {
  const value = input.value.$el.value;
  modal.value.$el.dismiss(value, 'confirm');
  setManualEntering(false);
}

const onWillDismiss = (ev: CustomEvent<OverlayEventDetail>) => {
  if (ev.detail.role === 'confirm') {
    emit('update:serialNumber', ev.detail.data);
    emit('onUpdate', () => {
      emit('performActions');
    });
  }
  setManualEntering(false);
}

const setManualEntering = (value: boolean) => {
  isManualEntering.value = value;
}

const onClearClick = async () => {
  const actionSheet = await actionSheetController.create({
    header: 'Are you sure?',
    buttons: [
      {
        text: 'Yes',
        role: 'confirm',
      },
      {
        text: 'No',
        role: 'cancel',
      },
    ],
  });

  await actionSheet.present();

  const { role } = await actionSheet.onDidDismiss();

  if ('confirm' === role) {
    emit('update:serialNumber', null);
    emit('onUpdate', () => {
      emit('revertActions');
    });
  }
}

const validate = (ev) => {
  const value = ev.target.value;

  if (!props.regex || '' === value) {
    return;
  }
  
  item.value.$el.classList.add('ion-invalid');

  const regex = new RegExp(props.regex);

  if (regex.test(value)) {
    item.value.$el.classList.remove('ion-invalid');
    item.value.$el.classList.add('ion-valid');
  } else {
    item.value.$el.classList.remove('ion-valid');
    item.value.$el.classList.add('ion-invalid');
  }
}
</script>

<style scoped>
.serial-number-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  max-width: 100%;
}
.serial-number {
  margin-left: 24px;
  font-size: 20px;
}
</style>