<template>
    <ion-grid :class="{ 'is-completed': isCompleted && !isScanning }">
        <ion-row>
            <ion-col size="6" class="ion-padding">
                <template v-if="!isScanning">
                    <TranslatableProperty :translatable="description" />
                    
                    <div v-if="remark" class="remark">
                        <TranslatableProperty :translatable="remark" />
                    </div>

                    <ion-list v-if="illustrations && 0 < illustrations.length" class="transparent-background">
                        <ion-list-header>Illustrations</ion-list-header>

                        <template v-for="illustration, index in illustrations" :key="index">
                            <template v-if="illustration.mediaObject">
                                <ion-item button @click="onIllustrationClick(illustration)" class="transparent-background">
                                    <ion-thumbnail slot="start">
                                        <img v-if="'image' === illustration.mimeType.split('/')[0]" :src="illustration.contentUrl">
                                        <img v-else-if="'application/pdf' === illustration.mimeType" src="/assets/pdf-icon.png">
                                        <img v-else src="/assets/pdf-icon.png">
                                    </ion-thumbnail>
                                    <ion-label>{{illustration.label}}</ion-label>
                                </ion-item>
                            </template>
                            <template v-else>
                                <ion-item button class="transparent-background">
                                    <ion-thumbnail slot="start">
                                        <img src="/assets/error-icon.png">
                                    </ion-thumbnail>
                                    <ion-label color="danger">Document not found!</ion-label>
                                </ion-item>
                            </template>
                        </template>
                    </ion-list>
                </template>
            </ion-col>

            <ion-col size="6">
                <div v-for="component, index in components" :key="index" :class="`component ${ {'component-error': component.error} }`">
                    <div v-if="component.error" class="transparent-background layout-row layout-align-start-center ion-padding">
                        <ion-icon :icon="alertCircleOutline" size="medium" color="danger" />&nbsp; {{component.error}}
                    </div>

                    <template v-if="'confirmation' === component.type && !isScanning">
                        <ConfirmationInput
                            v-model:isConfirmed="component.value"
                            :isDisabled="isDisabled || isLoading"
                            :label="component.properties.label" 
                            :expectedValue="component.properties.expectedValue" 
                            :isRequired="component.properties.isRequired"
                            @onUpdate="(callback) => { onUpdate(component, callback) }"
                            @performActions="performActions(component)"
                            @revertActions="revertActions(component)"
                        />
                    </template>

                    <template v-if="'batchnumber' === component.type">
                        <BatchNumberInput 
                            v-model:batchNumber="component.value"
                            :isDisabled="isDisabled || isLoading"
                            :productRevision="component.properties.productRevision"
                            :isScanning="isScanning"
                            :isRequired="component.properties.isRequired"
                            :scanTargetFormat="component.properties.scanTargetFormat"
                            @setIsScanning="(val) => $emit('setIsScanning', val)"
                            @onUpdate="(callback) => { onUpdate(component, callback) }"
                            @performActions="performActions(component)"
                            @revertActions="revertActions(component)"
                        />
                    </template>

                    <template v-if="'serialnumber' === component.type">
                        <SerialNumberInput 
                            v-model:serialNumber="component.value"
                            :isDisabled="false"
                            :productRevision="component.properties.productRevision" 
                            :isScanning="isScanning"
                            :isRequired="component.properties.isRequired"
                            :regex="component.properties.regexValidation"
                            :scanTargetFormat="component.properties.scanTargetFormat"
                            @setIsScanning="(val) => $emit('setIsScanning', val)"
                            @onUpdate="(callback) => { onUpdate(component, callback) }"
                            @performActions="performActions(component)"
                            @revertActions="revertActions(component)"
                        />
                    </template>

                    <template v-if="'select' === component.type && !isScanning">
                        <SelectInput 
                            v-model:selected="component.value"
                            :isDisabled="isDisabled || isLoading"
                            :label="component.properties.label" 
                            :options="component.properties.options"
                            :isRequired="component.properties.isRequired"
                            @onUpdate="(callback) => { onUpdate(component, callback) }"
                            @performActions="performActions(component)"
                        />
                    </template>

                    <template v-if="'text' === component.type && !isScanning">
                        <TextInput 
                            v-model:text="component.value"
                            :isDisabled="isDisabled || isLoading"
                            :label="component.properties.label"
                            :isRequired="component.properties.isRequired"
                            @onUpdate="onUpdate(component)"
                            @performActions="performActions(component)"
                        />
                    </template>

                    <template v-if="'string' === component.type && !isScanning">
                        <StringInput 
                            v-model:string="component.value"
                            :isDisabled="isDisabled || isLoading"
                            :label="component.properties.label"
                            :isRequired="component.properties.isRequired"
                            @onUpdate="(callback) => { onUpdate(component, callback) }"
                            @performActions="performActions(component)"
                        />
                    </template>

                    <template v-if="'date' === component.type && !isScanning">
                        <DateInput 
                            v-model:date="component.value"
                            :isDisabled="isDisabled || isLoading"
                            :label="component.properties.label" 
                            :isRequired="component.properties.isRequired"
                            @onUpdate="(callback) => { onUpdate(component, callback) }"
                            @performActions="performActions(component)"
                        />
                    </template>

                    <template v-if="'customer' === component.type && !isScanning">
                        <CustomerInput 
                            v-model:customer="component.value"
                            :isDisabled="isDisabled || isLoading"
                            :label="component.properties.label" 
                            :isRequired="component.properties.isRequired"
                            :useVehicleCustomerByDefault="component.properties.useVehicleCustomerByDefault" 
                            @onUpdate="(callback) => { onUpdate(component, callback) }"
                            @performActions="performActions(component)"
                        />
                    </template>

                    <template v-if="'employee' === component.type && !isScanning">
                        <EmployeeInput 
                            v-model:employee="component.value"
                            :isDisabled="isDisabled || isLoading"
                            :label="component.properties.label"
                            :isRequired="component.properties.isRequired"
                            @onUpdate="(callback) => { onUpdate(component, callback) }"
                            @performActions="performActions(component)"
                        />
                    </template>

                    <FormActions 
                        v-if="component.actions.length && !isScanning"
                        prefix="Component"
                        :actions="component.actions"
                        :isExpanded="!!component._actionsIsExpanded"
                        :isExecutable="!!component.value"
                        @setExpansion="(isExpanded) => component._actionsIsExpanded = isExpanded"
                        @onActionsCompleted="onComponentActionsCompleted(component)"
                    />
                </div>
            </ion-col>
        </ion-row>

        <FormActions 
            v-if="actions && actions.length && !isScanning"
            prefix="Item"
            :actions="actions"
            :isExpanded="!!actionsIsExpanded"
            :isExecutable="true"
            @setExpansion="(isExpanded) => actionsIsExpanded = isExpanded"
            @onActionsCompleted="$emit('onItemActionsCompleted')"
        />
    </ion-grid>

    <ion-modal ref="modal" :isOpen="!!activeIllustration" @willDismiss="activeIllustration = null">
        <template v-if="!!activeIllustration && activeIllustration.mediaObject && 'application/pdf' === activeIllustration.mimeType">
            <ion-button @click="onZoomClick(1)">Zoom 1</ion-button>
            <ion-button @click="onZoomClick(2)">Zoom 2</ion-button>
            <ion-button @click="onZoomClick(3)">Zoom 3</ion-button>
            <ion-button @click="onZoomClick(4)">Zoom 4</ion-button>
            <ion-content scroll="true" overflow-scroll="true" class="iframe-wrapper">
                <iframe id="illustration" data-tap-disabled="true" :src="activeIllustration.contentUrl" height="100%" width="100%" scrolling="yes" zooming="true" style="overflow: scroll; border: 0;"></iframe>
            </ion-content>
        </template>
        <template v-else-if="!!activeIllustration && activeIllustration.mediaObject && 'image' === activeIllustration.mimeType.split('/')[0]">
            <ion-content class="ion-padding">
              <ion-item>
                <ion-img :src="activeIllustration.contentUrl"></ion-img>
              </ion-item>
            </ion-content>
        </template>
        <template v-else-if="!!activeIllustration && activeIllustration.mediaObject">
            <ion-content class="ion-padding">
              <ion-item>
                <p>The mimetype "{{activeIllustration.mimeType}}" is not supported yet.</p>
              </ion-item>
            </ion-content>
        </template>
        <template v-else-if="!!activeIllustration && !activeIllustration.mediaObject">
            <ion-content class="ion-padding">
              <ion-item>
                <p>The document was not found.</p>
              </ion-item>
            </ion-content>
        </template>
    </ion-modal>
</template>

<script setup lang="ts">
import type { Component, Illustration, Action, Translatable } from '@/types/AssemblyFormType.interface';
import { IonGrid, IonRow, IonCol, IonImg, IonModal, IonContent, IonList, IonListHeader, IonItem, IonThumbnail, IonLabel, IonIcon, IonButton } from '@ionic/vue';
import { alertCircleOutline } from 'ionicons/icons';
import { ref, computed } from "vue";
import ConfirmationInput from '@/components/form/ConfirmationInput.vue';
import BatchNumberInput from '@/components/form/BatchNumberInput.vue';
import SerialNumberInput from '@/components/form/SerialNumberInput.vue';
import SelectInput from '@/components/form/SelectInput.vue';
import TextInput from '@/components/form/TextInput.vue';
import StringInput from '@/components/form/StringInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import CustomerInput from '@/components/form/CustomerInput.vue';
import EmployeeInput from '@/components/form/EmployeeInput.vue';
import FormActions from '@/components/FormActions.vue';
import TranslatableProperty from '@/components/TranslatableProperty.vue';
import { useAssemblyStore } from '@/stores/AssemblyStore';
import { storeToRefs } from 'pinia';

const props = defineProps<{ 
    actions: Action[] | null,
    isDisabled: boolean,
    description: Translatable | null,
    remark?: Translatable,
    illustrations?: Illustration[] | null,
    components: Component[] | null,
    isScanning?: boolean,
}>();

const emit = defineEmits(['setIsScanning', 'onItemActionsCompleted', 'performItemActions', 'revertItemActions']);

const assemblyStore = useAssemblyStore();
const { isLoading } = storeToRefs(assemblyStore);
const activeIllustration: Illustration = ref(null);
const actionsIsExpanded = ref(false);

const isCompleted = computed(() => {
    const itemActionsAreCompleted = 0 === props.actions.filter((action) => !action.completedAt).length;
    const componentsAndComponentActionsAreCompleted = 0 === props.components.filter((component) => component.properties.isRequired && !component.completedAt).length;
    return itemActionsAreCompleted && componentsAndComponentActionsAreCompleted;
});

const onIllustrationClick = (illustration) => {
    if (illustration.mimeType === 'application/pdf') {
        window.open(illustration.contentUrl, '_blank');
    } else {
        activeIllustration.value = illustration;
    }
}

const onZoomClick = (factor) => {
    document.getElementById('illustration').classList.remove('zoom-1');
    document.getElementById('illustration').classList.remove('zoom-2');
    document.getElementById('illustration').classList.remove('zoom-3');
    document.getElementById('illustration').classList.remove('zoom-4');

    document.getElementById('illustration').classList.add(`zoom-${factor}`);
}

const onUpdate = async (component, callback = null) => {
    await assemblyStore.updateComponent(component);

    if (0 === component.actions.length) {
        if ((null === component.value || "" === component.value) && component.properties.isRequired) {
            component.completedAt = null;
        } else {
            component.completedAt = (new Date()).toISOString();
        }
    }
    
    if (callback) {
        callback();
    }
}

const performActions = async (component) => {
    if (component.error) {
        // SoundEffect.play({ id: 'error' });
        return;
    }
    
    const actions = component.actions.filter((action: Action) => !action.completedAt);

    for (const action of actions) {
        await assemblyStore.performAction(action);
    }
    
    onComponentActionsCompleted(component);
}

const revertActions = async (component) => {
    // First revert the actions of the item
    emit('revertItemActions');

    const actions = component.actions.filter((action: Action) => action.completedAt);

    for (const action of actions.reverse()) {
        await assemblyStore.revertAction(action);
    }  

    if (0 < component.actions.filter((action: Action) => !action.completedAt).length) {
        component.completedAt = null;
    }
}

const onComponentActionsCompleted = (component) => {
    for (const action of component.actions) {
        if (!action.completedAt) {
            // SoundEffect.play({ id: 'error' });
            return;
        }
    }

    component.completedAt = (new Date()).toISOString();

    // Check if the next component is required and if the actions can be executed
    const componentIndex = props.components.indexOf(component);
    
    if ((componentIndex + 1) < props.components.length) {
        // There are more components in this item after this one
        const nextComponent = props.components[componentIndex + 1];

        if (!nextComponent.properties.isRequired && 'actions' in nextComponent && 0 < nextComponent.actions.length) {
            // It should normally not be possible to assign an action to a component which is not required, but anyway..
            return onComponentActionsCompleted(nextComponent);
        }
    }

    // Now check if all the components in this item are completed
    const allComponentsAreCompleted = 0 === props.components.filter((component) => {
        if (component.properties.isRequired && !component.value) {
            return true;
        }

        if ('actions' in component && 0 < component.actions.filter(action => !action.completedAt).length) {
            return true;
        }

        return false;
    }).length;

    if (allComponentsAreCompleted) {
        emit('performItemActions');
    } else {
        // SoundEffect.play({ id: 'click' });
    }
}
</script>

<style scoped>
.remark {
  font-size: 80%;
  font-style: italic;
}
.is-confirmed {
  background-color: #a3ffa7;
}
.component:not(last-of-type) {
    margin-bottom: 6px;
}
.component-error {
    background-color: #ff00001a;
}
ion-modal::part(content) {
    height: 90% !important;
    width: 90% !important;
}
</style>

<style>
.zoom-2 {
    zoom: 2;
    -moz-transform: scale(2);
    -moz-transform-origin: 0 0;
    -o-transform: scale(2);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(2);
    -webkit-transform-origin: 0 0;
}
.zoom-3 {
    zoom: 3;
    -moz-transform: scale(3);
    -moz-transform-origin: 0 0;
    -o-transform: scale(3);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(3);
    -webkit-transform-origin: 0 0;
}
.zoom-4 {
    zoom: 3;
    -moz-transform: scale(4);
    -moz-transform-origin: 0 0;
    -o-transform: scale(4);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(4);
    -webkit-transform-origin: 0 0;
}
</style>