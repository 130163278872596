<template>
    <div class="layout-row layout-align-start-center">
        <span class="layout-column product-unit-number ion-padding ion-text-end">
            <span class="product-description">{{productDescription}}</span>
            <span>{{number}}</span>
        </span>
        <ion-button @click="onCloseClick" :disabled="isLoading" v-if="!isLoading">
                Close
        </ion-button>
        <template v-else>
            <ion-spinner class="ion-margin"></ion-spinner>
        </template>
    </div>
</template>

<script setup lang="ts">
import { IonButton, IonSpinner } from '@ionic/vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useAssemblyStore } from '@/stores/AssemblyStore';

const router = useRouter();
const assemblyStore = useAssemblyStore();
const { number, productDescription, isLoading } = storeToRefs(assemblyStore);

const onCloseClick = () => {
    assemblyStore.reset();
    router.push({
        name: 'UnitSelector',
    })
}
</script>

<style scoped>
.product-unit-number {
    font-size: 20px;
}
.product-description {
    font-size: 14px;
    opacity: 0.7;
}
</style>