<template>
  <template v-if="!isScanning">
    <div v-if="batchNumber" class="batch-number-container ion-text-end ion-padding-end" :class="{ 'is-completed': batchNumber, 'is-required': isRequired }">
      <!--<ion-button fill="clear" color="medium" @click="onClearClick" :disabled="isDisabled">
        <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
      </ion-button>-->

      <ion-button color="success" @click="onClearClick" :disabled="isDisabled">
        <ion-icon slot="end" :icon="closeOutline"></ion-icon>
        <span class="batch-number">{{batchNumber}}</span>
      </ion-button>
      
      <!--<span class="batch-number">{{batchNumber}}</span>-->
    </div>

    <div v-if="!batchNumber" class="ion-text-end ion-padding-end todo" :class="{ 'is-required': isRequired }">
      <ion-button color="medium" @click="setManualEntering(true)" :disabled="isDisabled">
        <ion-icon slot="start" :icon="pencil"></ion-icon>
        Handmatig
      </ion-button>

      <ion-button color="medium" class="ion-padding-start" @click="startScan" :disabled="isDisabled" v-if="Capacitor.isNativePlatform()">
        <ion-icon slot="start" :icon="barcodeOutline"></ion-icon>
        Scan {{ props.scanTargetFormat ? props.scanTargetFormat : 'code' }}
      </ion-button>
      
      <ion-modal ref="modal" :isOpen="isManualEntering" @willDismiss="onWillDismiss">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click="setManualEntering(false)">Cancel</ion-button>
            </ion-buttons>
            <ion-title>Batch number</ion-title>
            <ion-buttons slot="end">
              <ion-button :strong="true" @click="onConfirmManualInput">Confirm</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-item>
            <ion-label position="stacked">Enter batch number</ion-label>
            <ion-input ref="input" type="text" placeholder="batch number"></ion-input>
          </ion-item>
        </ion-content>
      </ion-modal>
    </div>
  </template>
</template>

<script setup lang="ts">
import { actionSheetController, IonButton, IonIcon, IonModal, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonItem, IonLabel, IonInput } from '@ionic/vue';
import { OverlayEventDetail } from '@ionic/core/components';
import { barcodeOutline, pencil, closeOutline } from 'ionicons/icons';
import { BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { Capacitor } from '@capacitor/core';
import { Haptics } from '@capacitor/haptics';
import { ref } from 'vue';

const props = defineProps<{
    isDisabled: boolean,
    isRequired: boolean | null,
    isScanning: boolean,
    productRevision?: number | null,
    batchNumber?: string | null,
    scanTargetFormat?: string | null,
}>();

const emit = defineEmits([
  'update:batchNumber',
  'setIsScanning',
  'onUpdate',
  'performActions',
  'revertActions',
]);

const modal = ref(null);
const input = ref(null);
const isManualEntering = ref(false);

const startScan = async () => {  
  if (!Capacitor.isNativePlatform()) {
    return;
  }
  
  emit('setIsScanning', true);

  setTimeout(() => {
    BarcodeScanner.enableTorch();
  }, 100);

  const listener = await BarcodeScanner.addListener('barcodeScanned',
    async result => {
      if (!props.isScanning) {
        // Avoid double scans
        return;
      }

      await BarcodeScanner.removeAllListeners();
      await BarcodeScanner.stopScan();
      await Haptics.vibrate();

      emit('setIsScanning', false);
      document.querySelector('body')?.classList.remove('barcode-scanner-active');
      
      emit('update:batchNumber', result.barcode.rawValue);
      emit('onUpdate', () => {
        emit('performActions');
      });
    },
  );

  const formats = [];

  if ('barcode' === props.scanTargetFormat) {
    formats.push(BarcodeFormat.Codabar);
    formats.push(BarcodeFormat.Code39);
    formats.push(BarcodeFormat.Code93);
    formats.push(BarcodeFormat.Code128);
    formats.push(BarcodeFormat.Ean8);
    formats.push(BarcodeFormat.Ean13);
    formats.push(BarcodeFormat.Itf);
    formats.push(BarcodeFormat.QrCode);
    formats.push(BarcodeFormat.UpcA);
    formats.push(BarcodeFormat.UpcE);
  }

  if ('qrcode' === props.scanTargetFormat) {
    formats.push(BarcodeFormat.Aztec);
    formats.push(BarcodeFormat.DataMatrix);
    formats.push(BarcodeFormat.Pdf417);
    formats.push(BarcodeFormat.QrCode);
  }

  await BarcodeScanner.startScan({
    formats: formats,
  });
};

const onConfirmManualInput = () => {
  const value = input.value.$el.value;
  modal.value.$el.dismiss(value, 'confirm');
  setManualEntering(false);
}

const onWillDismiss = (ev: CustomEvent<OverlayEventDetail>) => {
  if (ev.detail.role === 'confirm') {
    emit('update:batchNumber', ev.detail.data);
    emit('onUpdate', () => {
      emit('performActions');
    });
  }
  setManualEntering(false);
}

const setManualEntering = async (value: boolean) => {
  isManualEntering.value = value;
}

const onClearClick = async () => {
  const actionSheet = await actionSheetController.create({
    header: 'Are you sure?',
    buttons: [
      {
        text: 'Yes',
        role: 'confirm',
      },
      {
        text: 'No',
        role: 'cancel',
      },
    ],
  });

  await actionSheet.present();

  const { role } = await actionSheet.onDidDismiss();

  if ('confirm' === role) {
    emit('update:batchNumber', null);
    emit('onUpdate', () => {
      emit('revertActions');
    });
  }
}
</script>

<style scoped>
.batch-number-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  max-width: 100%;
}
.batch-number {
  /*margin-left: 24px;*/
  font-size: 20px;
}
</style>