<template>
  <ion-page>
    <ion-content v-if="!isScanning" :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-col size="5" class="layout-row layout-align-start-center">
            <ActiveEmployee />            
          </ion-col>
        </ion-row>
      </ion-grid>

      <template v-if="!isManualEntering && !productUnitId">
        <div id="container">
          <ion-grid>
            <ion-row>
              <ion-col offset-md="3" size-md="6">
                <ion-button @click="startScan" expand="block" fill="solid" size="large" :disabled="isLoading || !Capacitor.isNativePlatform()">Scan barcode or QR code</ion-button>
              </ion-col>
            </ion-row>
            <br />
            <ion-row>
              <ion-col offset-md="3" size-md="6">
                <ion-button @click="isManualEntering = true" expand="block" fill="outline" size="large" :disabled="isLoading">Enter manually</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </template>

      <template v-else-if="isManualEntering && !productUnitId">
        <div id="container">
          <ion-grid>
            <ion-row>
              <ion-col offset-md="3" size-md="6">
                <ion-segment v-model="manualInputType">
                  <ion-segment-button value="reportingCode">
                    <ion-label>Meldcode</ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="number">
                    <ion-label>VIN</ion-label>
                  </ion-segment-button>
                </ion-segment>
                <br />
                
                <template v-if="'reportingCode' === manualInputType">
                  <div class="layout-row">
                    <ion-select interface="popover" v-model="reportingCodePrefix">
                      <ion-select-option v-for="prefix, index in reportingCodePrefixes" :value="prefix" :key="index">{{prefix}}</ion-select-option>
                    </ion-select>
                    <span class="flex-5"/>
                    <ion-input v-model="searchTerm" type="number" max=9999></ion-input>
                  </div>
                </template>
                <template v-if="'number' === manualInputType">
                  <div class="layout-row">
                    <ion-input v-model="searchTerm" type="text" maxlength=17></ion-input>
                  </div>
                </template>
                
                <br />
                <ion-button @click="onNumberEntered" expand="block" fill="solid" size="large" :disabled="isLoading">Ok</ion-button>
                <br />
                <ion-button @click="onCancel" expand="block" fill="outline" size="large" :disabled="isLoading">Back</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </template>

      <template v-else-if="productUnitId">
        <div id="container">
          <ion-grid>
            <ion-row>
              <ion-col offset-md="3" size-md="6">
                <h1>{{number}}</h1>
                <p>Batch: {{batchNumber}}</p>
                <p>Product: {{productNumber}}</p>
                <p>Description: {{productDescription}}</p>
                <p>Initiated at: {{createdAt}}</p>
                <br />
                <ion-button @click="onConfirm" expand="block" fill="solid" size="large" :disabled="isLoading">Confirm</ion-button>
                <br />
                <ion-button @click="onCancel" expand="block" fill="outline" size="large" :disabled="isLoading">Back</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </template>
    </ion-content>

    <ScanOverlay v-if="isScanning" @cancel="isScanning = false" />
  </ion-page>
</template>

<script setup lang="ts">
import { IonContent, IonSelect, IonSelectOption, IonPage, IonButton, IonLabel, IonCol, IonGrid, IonRow, IonInput, IonSegment, IonSegmentButton, loadingController } from '@ionic/vue';
import { useRouter } from 'vue-router'
import { BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { Haptics } from '@capacitor/haptics';
import ScanOverlay from '@/components/ScanOverlay.vue';
import ActiveEmployee from '@/components/ActiveEmployee.vue';
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAssemblyStore } from '@/stores/AssemblyStore';
import NonUniqueResultsError from "@/errors/NonUniqueResultsError";
import NoResultError from "@/errors/NoResultError";
import { Capacitor } from '@capacitor/core';

const assemblyStore = useAssemblyStore();
const router = useRouter()
const { isLoading, productUnitId, number, batchNumber, productNumber, productDescription, createdAt } = storeToRefs(assemblyStore);

const searchTerm = ref('') as any;
const isScanning = ref(false);
const isManualEntering = ref(false);
const manualInputType = ref('reportingCode');
const reportingCodePrefixes = ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
let reportingCodePrefix = reportingCodePrefixes[(new Date).getFullYear() - 2020];


const startScan = async () => {
  isScanning.value = true;
  
  if (!Capacitor.isNativePlatform()) {
    return;
  }
  
  setTimeout(() => {
    BarcodeScanner.enableTorch();
  }, 100);

  const listener = await BarcodeScanner.addListener('barcodeScanned',
    async result => {
      if (!isScanning.value) {
        // Avoid double scans
        return;
      }

      await BarcodeScanner.removeAllListeners();
      await BarcodeScanner.stopScan();
      await Haptics.vibrate();

      searchTerm.value = result.barcode.rawValue;
      isScanning.value = false;
      document.querySelector('body')?.classList.remove('barcode-scanner-active');
      
      onNumberEntered();
    },
  );

  await BarcodeScanner.startScan({
    formats: [
      BarcodeFormat.Codabar,
      BarcodeFormat.Code39,
      BarcodeFormat.Code93,
      BarcodeFormat.Code128,
      BarcodeFormat.Ean8,
      BarcodeFormat.Ean13,
      BarcodeFormat.Itf,
      BarcodeFormat.UpcA,
      BarcodeFormat.UpcE,
    ]
  });
};

const onCancel = () => {
  isManualEntering.value = false;
  isScanning.value = false;
  searchTerm.value = '';
  assemblyStore.reset();
}

const onNumberEntered = async () => {
  const loading = await loadingController.create({
    message: 'Searching for unit...',
    spinner: 'circles'
  });
    
  loading.present();

  try {
    if (isManualEntering.value && 'reportingCode' === manualInputType.value) {
      await assemblyStore.search(`${reportingCodePrefix}${searchTerm.value}`, 'reportingCode');
    } else {
      await assemblyStore.search(searchTerm.value);
    }

    loading.dismiss();
  } catch (error) {
    loading.dismiss();

    if (error instanceof NoResultError) {
      return alert(`No results for ${searchTerm.value}`);
    } else if (error instanceof NonUniqueResultsError) {
      return alert(`Multiple results for ${searchTerm.value}`);
    }
  }

  onConfirm();
}

const onConfirm = () => {
  router.push({
    name: 'AssemblyForm',
    params: {
      id: productUnitId.value,
      index: assemblyStore.nextIndex,
    }
  });
}
</script>

<style scoped>
.transparent-background {
    --background: none;
}
.camera-scope {
  position: absolute;
  display: inline-block;
  /* left: 0; */
  /* right: 0; */
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 50vw;
  outline: 200vw solid #ffffffd4;
}
.camera-scope-success {
  outline-color: #21c500;
}

.scan-options-container {
  position: absolute;
  display: inline-block;
  transform: translateX(-50%);
  bottom: 10vh;
  width: 40vw;
}

.btn-scanning-cancel2 {
  position: absolute;
  display: inline-block;
  transform: translateX(-50%);
  bottom: 10vh;
  width: 40vw;
}

ion-input {
  border: 1px solid;
  border-radius: 10px;
  height: 70px;
  font-size: 28px;
}

#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;  
  color: #8c8c8c;  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
