<template>
    <div :class="{ 'is-completed': isCompleted && !isScanning }">
        <ion-toolbar v-if="!isScanning" class="transparent-background ion-margin-top ion-padding-top">
            <ion-title size="large">{{ name }}</ion-title>
        </ion-toolbar>

        <template v-for="item, index in items" :key="index">
            <AssemblyFormItem 
                :actions="item.actions ?? []"
                :description="item.description" 
                :remark="item.remark" 
                :components="item.components" 
                :isScanning="isScanning" 
                :illustrations="item.illustrations"
                :isDisabled="isDisabled"
                @setIsScanning="setIsScanning"
                @performItemActions="performItemActions(item)"
                @revertItemActions="revertItemActions(item)"
                @onItemActionsCompleted="onItemActionsCompleted(item)"
            />

            <hr />
        </template>

        <FormActions 
            v-if="actions && actions.length && !isScanning"
            prefix="Section"
            :actions="actions"
            :isExpanded="!!actionsIsExpanded"
            :isExecutable="isCompleted"
            @setExpansion="(isExpanded) => actionsIsExpanded = isExpanded"
            @onActionsCompleted="$emit('onActionsCompleted')"
        />
    </div>
</template>

<script setup lang="ts">
import { IonToolbar, IonTitle } from '@ionic/vue';
import AssemblyFormItem from '@/components/AssemblyFormItem.vue';
import FormActions from '@/components/FormActions.vue';
import type { Item, Action } from '@/types/AssemblyFormType.interface';
import { computed, ref } from 'vue'
import { useAssemblyStore } from '@/stores/AssemblyStore';

const props = defineProps<{
    actions: Action[],
    isDisabled: boolean,
    name: string | null,
    items: Item[] | null,
    isScanning: boolean,
}>();

const emit = defineEmits(['setIsScanning', 'onSectionActionsCompleted', 'performSectionActions', 'revertSectionActions']);

const assemblyStore = useAssemblyStore();
const actionsIsExpanded = ref(false);

const isCompleted = computed(() => {
    for (const item of props.items) {
        for (const component of item.components) {
            if (component.properties.isRequired && !component.value) {
                return false;
            }
        }
    }

    return true;
});

const performItemActions = async (item) => {
    if ('actions' in item) {
        const actions = item.actions.filter((action: Action) => !action.completedAt);

        for (const action of actions) {
            await assemblyStore.performAction(action);
        }
    }
    
    onItemActionsCompleted(item);
}

const revertItemActions = async (item) => {
    // First revert the actions of the Section
    emit('revertSectionActions');

    const actions = item.actions.filter((action: Action) => action.completedAt);

    for (const action of actions.reverse()) {
        await assemblyStore.revertAction(action);
    }  

    if (0 < item.actions.filter((action: Action) => !action.completedAt).length) {
        item.completedAt = null;
    }
}

const onItemActionsCompleted = (item) => {
    if (!('actions' in item)) {
        return emit('performSectionActions');
    }

    for (const action of item.actions) {
        if (!action.completedAt) {
            // SoundEffect.play({ id: 'error' });
            return;
        }
    }

    item.completedAt = (new Date()).toISOString();

    // Check if there are more items in this section that can be executed
    let nextItem, itemIndex = props.items.indexOf(item) + 1;

    while (itemIndex in props.items) {
        // Check if the actions of the next item can be executed
        nextItem = props.items[itemIndex];

        for (const component of nextItem.components) {
            if (0 === component.actions.filter((action: Action) => !action.completedAt).length) {
                // There are no pending actions
                return performItemActions(nextItem);
            }
        }

        itemIndex++;
    }

    // Now check if all the items are completed
    const allItemsAreCompleted = 0 === props.items.filter((item) => {
        return 0 !== item.components.filter((component) => {
            if (component.properties.isRequired && !component.value) {
                return true;
            }

            if ('actions' in component && 0 < component.actions.filter(action => !action.completedAt).length) {
                return true;
            }

            return false;
        }).length;
    }).length;

    if (allItemsAreCompleted) {
        // All following items in this section have been checked, no actions to execute.
        // Now propagating the actions to the section
        emit('performSectionActions');
    } else {
        // SoundEffect.play({ id: 'click' });
    }
}

const setIsScanning = (value) => {
    emit('setIsScanning', value);
}
</script>
