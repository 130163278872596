<template>
  <ion-grid :class="{ 'is-completed': string, 'todo': !string, 'is-required': isRequired }">
    <ion-row>
      <ion-col offset="6" size="6">
        <ion-item ref="item" class="transparent-background">
          <ion-label position="floating" v-if="label">
            <TranslatableProperty :translatable="label" />
          </ion-label>
          <ion-input v-model="initialValue" type="text" :placeholder="placeholder" @ionChange="onChange" @ionBlur="onBlur" @ionInput="validate" :disabled="isDisabled"></ion-input>
          <ion-note slot="error">Incorrecte waarde</ion-note>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup lang="ts">
import type { Translatable } from '@/types/AssemblyFormType.interface';
import TranslatableProperty from '@/components/TranslatableProperty.vue';
import { IonGrid, IonCol, IonRow, IonItem, IonLabel, IonInput, IonNote } from '@ionic/vue';
import { ref, toRef } from "vue";
import { computed } from "vue";
import { storeToRefs } from 'pinia';
import { useEmployeeStore } from '@/stores/EmployeeStore';

const props = defineProps<{ 
  isDisabled: boolean,
  label?: Translatable | null,
  isRequired?: boolean | null,
  string?: string | null,
  regex?: string | null,
}>();

const _string = toRef(props, 'string');
const initialValue = ref('');
const item = ref(null);

const emit = defineEmits(['update:string', 'onUpdate', 'performActions']);

const employeeStore = useEmployeeStore();
const { activeEmployee } = storeToRefs(employeeStore);

const placeholder = computed(() => {
  return props.label[activeEmployee.value.language];
});

const onChange = (event) => {
  emit('update:string', event.detail.value);
}

const onBlur = () => {
  emit('onUpdate', () => {
    emit('performActions');
  });
}

const validate = (ev) => {
  const value = ev.target.value;

  if (!props.regex || '' === value) {
    return;
  }
  
  item.value.$el.classList.add('ion-invalid');

  const regex = new RegExp(props.regex);

  if (regex.test(value)) {
    item.value.$el.classList.remove('ion-invalid');
    item.value.$el.classList.add('ion-valid');
  } else {
    item.value.$el.classList.remove('ion-valid');
    item.value.$el.classList.add('ion-invalid');
  }
}

initialValue.value = _string.value;
</script>